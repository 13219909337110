import {observer} from 'mobx-react-lite';
import {Slide, ToastContainer} from 'react-toastify';
import React, {FC, useEffect, useState} from 'react';
import {useMatches, Outlet, useLocation} from 'react-router-dom';
import {IProfile, TSession, VerdocsEndpoint} from '@verdocs/js-sdk';
import {AnalyticsManager} from '../Managers/AnalyticsManager';
import {AppState, setSession} from '../AppState';
import {AnonymousRoutes} from './MainRouter';
import * as MainViews from '../Views/Main';

export const Root: FC = observer(() => {
  // Must be used in a router
  const matches = useMatches();
  const location = useLocation();
  const [loggedOut, setLoggedOut] = useState(false);
  const closestMatch = matches[matches.length - 1];

  // Do not refactor this. This is one of the few instances where we DO need to force a dependency on an observable.
  // MobX does its redraw magic by observing what a view "reads from" when it renders. It can't do that here because
  // this is just a fragment/stub component that never displays the value. But the React linter gets confused thinking
  // we don't need AppState.sessionLoaded as a dependency of the useEffect below because it's an external primitive.
  // It emits a warning which breaks the build because we're strict about warnings in the build script. This step
  // makes the linter happy without changing the function of the operation.
  const sessionLoaded = AppState.sessionLoaded;

  // See https://github.com/remix-run/react-router/issues/8503#issuecomment-1034263630
  // const state = location.state as {originalPath?: string};

  useEffect(() => {
    AnalyticsManager.recordPageView(closestMatch.pathname, closestMatch.id);
  }, [sessionLoaded, closestMatch]);

  useEffect(() => {
    const endpoint = VerdocsEndpoint.getDefault();

    const unsub = endpoint.onSessionChanged((_endpoint: VerdocsEndpoint, session: TSession, profile: IProfile | null) => {
      const originalPath = localStorage.getItem('originalPath');
      console.log('Session changed', {session, profile, originalPath});

      if (session) {
        setSession(session, profile);

        if (originalPath) {
          console.log('Redirecting to original path', originalPath);
          localStorage.removeItem('originalPath');
          window.location.href = originalPath;
        }
      } else {
        setSession(null, null);

        if (!AnonymousRoutes.includes(closestMatch.id)) {
          console.log('No session, route requires auth, going to Login', location.pathname);
          localStorage.removeItem('originalPath');
          window.location.href = '/login';
        } else {
          console.log('No session, route allows anonymous views, staying on path', AnonymousRoutes, closestMatch.id);
        }
      }
    });

    endpoint.loadSession();

    return unsub;
  }, []);

  if (!AppState.sessionLoaded) {
    console.log('[ROOT] Waiting for session load');
    return (
      <div className="full-page-loader">
        <img src="/preloader.gif" alt="Verdocs Logo" className="w-[100px] h-[100px]" />
      </div>
    );
  }

  if (loggedOut) {
    return (
      <div id="root">
        <div className="full-page-loader">
          <img src="/preloader.gif" alt="Verdocs Logo" style={{width: '100px', height: '100px'}} />
        </div>
      </div>
    );
  }

  // TODO: When logging out there's a brief flash where the sidebar disappears but the main app
  // does not. This is because Sidebar only cares about AppState.isAuthenticated but the rest of
  // the views are tied to AnonymousRoutes vs. onSessionChanged and the former updates faster.
  // We should unify this so the sidebar doesn't disappear until the rest of the app is ready,
  // either by making the Sidebar follow the same rules, or by making the rest of the app just
  // use AppState.isAuthenticated.

  return (
    <div className="flex flex-col w-full h-full tablet:flex-row">
      {AppState.isAuthenticated &&
      window.location.pathname !== '/oauth2/authorize' &&
      window.location.pathname !== '/verified' &&
      !window.location.pathname.includes('/embeds/') ? (
        <MainViews.Sidebar onSignout={() => setLoggedOut(true)} />
      ) : (
        <></>
      )}

      <Outlet />

      <ToastContainer
        position="top-center"
        autoClose={1200}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        newestOnTop
        transition={Slide}
        // bodyClassName="toast-body"
      />
    </div>
  );
});
