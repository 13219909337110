import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {InboxIcon} from '@heroicons/react/24/solid';
import {UserGroupIcon, TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline';
import {IProfile, VerdocsEndpoint, deleteOrganizationMember, TRole} from '@verdocs/js-sdk';
import {Spinner, Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../../../Components';
import {useOrganizationMembers} from '../../../react-sdk/queries';
import {EditMemberDialog, OkCancelDialog} from '../../../Dialogs';
import {AppState} from '../../../AppState';

const getRoleLabel = (roles: TRole[]) => {
  if (roles.includes('owner')) return 'Owner';
  if (roles.includes('admin')) return 'Admin';
  if (roles.includes('member')) return 'Member';
  if (roles.includes('basic_user')) return 'Basic User';
  if (roles.includes('contact')) return 'Contact';
};

export const MembersTable: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id;
  const profileId = AppState.currentProfile?.id || '';

  const [working, setWorking] = React.useState(false);
  const [deletingMemberId, setDeletingMemberId] = useState('');
  const [editingMember, setEditingMember] = useState<IProfile | null>(null);
  const membersQuery = useOrganizationMembers(organizationId);
  const members = membersQuery.data || [];

  if (membersQuery.isLoading) {
    return <Spinner mode="dark" />;
  }

  if (membersQuery.isError) {
    return <div className="">Unable to load Organization members. Please try again later.</div>;
  }

  const handleDeleteMember = async () => {
    setWorking(true);
    try {
      await deleteOrganizationMember(VerdocsEndpoint.getDefault(), deletingMemberId);
      membersQuery.refetch().catch((e: any) => console.log('Unknown Error', e));
      toast.info('Member deleted.');
    } catch (e) {
      console.log('e', e);
      toast.error('Error deleting member, please try again later.');
    }
    setDeletingMemberId('');
    setWorking(false);
  };

  return (
    <>
      <Table>
        {/*<TableCaption>A list of your members.</TableCaption>*/}
        <TableHeader>
          <TableRow>
            <TableHead className="pl-0 tablet:pl-4">Name</TableHead>
            <TableHead className="hidden tablet:table-cell">Email</TableHead>
            <TableHead>Role</TableHead>
            <TableHead className="text-center hidden tablet:table-cell">Actions</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {members.map((member) => (
            <TableRow key={member.id}>
              <TableCell className="pl-0 tablet:pl-4">
                <div className="flex flex-row gap-2 items-center overflow-hidden truncate">
                  {member.picture ? (
                    <img src={member.picture} className="size-8 rounded-full hidden laptop:flex" alt="Profile Photo" />
                  ) : (
                    <div
                      className={`size-8 rounded-full flex-[0_0_32px] items-center justify-center bg-gray-600 text-white hidden laptop:flex`}>
                      {member.first_name?.substring(0, 1) || ''}
                      {member.last_name?.substring(0, 1) || ''}
                    </div>
                  )}

                  {`${member.first_name} ${member.last_name}`}
                </div>
              </TableCell>
              <TableCell className="hidden tablet:table-cell truncate">
                <InboxIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {member.email}
              </TableCell>
              <TableCell>
                <UserGroupIcon className="w-6 h-6 text-gray-400 mr-3 hidden laptop:inline" />
                {getRoleLabel(member.roles || [])}
              </TableCell>
              <TableCell className="text-center hidden tablet:table-cell">
                <div className="flex flex-row gap-2">
                  {member.id !== profileId && !member.roles?.includes('owner') && (
                    <TrashIcon
                      className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer"
                      onClick={() => setDeletingMemberId(member.id)}
                    />
                  )}
                  {member.id !== profileId && (
                    <PencilSquareIcon
                      className="w-6 h-6 text-gray-400 mr-3 hover:text-red-600 cursor-pointer"
                      onClick={() => setEditingMember(member)}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {deletingMemberId && (
        <OkCancelDialog onCancel={() => setDeletingMemberId('')} onOk={handleDeleteMember}>
          Are you sure you want to delete this member? This action cannot be undone. All templates, envelopes, and other records will be
          reassigned to you.
        </OkCancelDialog>
      )}

      {editingMember && (
        <EditMemberDialog
          member={editingMember}
          onClose={() => {
            setEditingMember(null);
            membersQuery.refetch();
          }}
        />
      )}

      {working && (
        <div className="absolute inset-0 bg-[#0000007f] flex flex-col items-center pt-8 text-white font-lg font-semibold">
          <Spinner mode="light" />
          <div className="mt-4">Please wait...</div>
        </div>
      )}
    </>
  );
});
