import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import React, {FC, useState} from 'react';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FormProvider, useForm} from 'react-hook-form';
import {VerdocsEndpoint, setWebhooks, IWebhook, getWebhooks} from '@verdocs/js-sdk';
import {CheckboxInput, TextInput, Divider, PageTitle, Spinner, FlexFill, HelpDialog} from '../../Components';
import {useOrganizationWebhook} from '../../react-sdk/queries';

export const Webhooks: FC = observer(() => {
  const webhooksQuery = useOrganizationWebhook();
  const [submitting, setSubmitting] = useState(false);

  console.log('wd', webhooksQuery.data);
  const form = useForm<IWebhook>({
    mode: 'all',
    defaultValues: async () => getWebhooks(VerdocsEndpoint.getDefault()),
  });

  const handleSubmit = (values: any) => {
    setSubmitting(true);
    setWebhooks(VerdocsEndpoint.getDefault(), values)
      .then((r) => {
        setSubmitting(false);
        console.log('Updated webhook', r);
        toast.success('Settings saved');
      })
      .catch((e) => {
        setSubmitting(false);
        toast.error('Error saving Webhook settings: ' + e.response?.data?.error || e.message);
      });
  };

  if (webhooksQuery.isLoading) {
    return (
      <div className="p-4 h-full overflow-y-scroll">
        <PageTitle>Webhooks</PageTitle>
        <div className="mt-4" />
        <Spinner mode="dark" />
      </div>
    );
  }

  return (
    <div className="p-4 pb-12 h-full overflow-y-scroll">
      <PageTitle>
        Webhooks
        <FlexFill />
        <HelpDialog title="Webhooks">
          <p>
            Webhooks are server-to-server notifications that may be sent to a URL of your choice when certain events occur within your
            organization. Webhook events are sent via POST requests with a JSON payload containing details about the event.
          </p>
          <p>
            If you are interested in using Webhooks but not yet ready to provide an endpoint of your own, we recommend the excellent{' '}
            <a href="https://webhook.site/" target="_blank">
              Webhook.site
            </a>
            &nbsp; tool for testing and development. This is a free service that provides a unique URL for testing Webhooks, with a modest
            monthly fee for more advanced features.
          </p>
        </HelpDialog>
      </PageTitle>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <TextInput label="Webhook URL" name="url" />

          <CheckboxInput label="Active" name="active" explainer="Pause delivery temporarily." />

          <div className="flex flex-row gap-3">
            <div className="flex flex-1 flex-col">
              <h3>Envelope Activity</h3>
              <Divider />
              <CheckboxInput label="Created" name="events.envelope_created" />
              <CheckboxInput label="Updated" name="events.envelope_updated" />
              <CheckboxInput label="Completed" name="events.envelope_completed" />
              <CheckboxInput label="Canceled" name="events.envelope_canceled" />
            </div>

            <div className="flex flex-1 flex-col">
              <h3>Template Activity</h3>
              <Divider />
              <CheckboxInput label="Created" name="events.template_created" />
              <CheckboxInput label="Updated" name="events.template_updated" />
              <CheckboxInput label="Deleted" name="events.template_deleted" />
              <CheckboxInput label="Used" name="events.template_used" />
            </div>
          </div>

          <VerdocsButton type="submit" label="Update Settings" size="normal" disabled={submitting} />
        </form>
      </FormProvider>
    </div>
  );
});
